






























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import { MidiEvent, VMixInput, Trigger, ApiFunctionParams, apiFunctions } from '@/app/models'
import { VMixRemote } from '@/app/vmix-remote'

@Component
export default class Triggers extends Vue {
  private valid = false
  private search = ''
  private dialog = false
  private dialogDelete = false
  private editedIndex = -1
  private editedItem = new Trigger()
  private defaultItem = new Trigger()
  private editNote: number | string = ''
  private editVelocity: number | string | null = ''
  private editChannel: number | string | null = null
  private inputLookup: Array<VMixInput> = []

  @Prop() vMixRemote!: VMixRemote

  data () {
    return {
      headers: [
        { text: 'Event', value: 'event' },
        { text: 'Note', value: 'note' },
        { text: 'Velocity', value: 'velocity' },
        { text: 'Channel', value: 'channel' },
        { text: 'Function', value: 'apiFunctionName' },
        { text: 'Parameters', value: 'apiFunctionParams' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      valid: false,
      eventRules: [
        (e?: MidiEvent | null) =>
          (e !== undefined && e !== null && e > 0) || 'Event is required'
      ],
      noteRules: [
        (n?: number | string) => (n != null && n !== '') || 'Note is required',
        (n?: number | string) =>
          (n !== undefined && n >= 0 && n < 128) ||
          'Note must be between 0 and 127'
      ],
      velocityRules: [
        (v?: number | string | null) =>
          v === undefined ||
          v === null ||
          v === '' ||
          (v >= 0 && v < 128) ||
          'Velocity must be between 0 and 127'
      ],
      functionRules: [
        (f?: string) => !!f || 'Function is required'
      ],
      inputRules: [
        (i?: string) => !!i || 'Input is required'
      ],
      midiEventLookup: [
        { event: null, text: '' },
        { event: MidiEvent.NoteOn, text: 'Note On' },
        { event: MidiEvent.NoteOff, text: 'Note Off' }
      ],
      channelLookup: [
        { channel: null, text: 'Any channel' },
        { channel: 0, text: 'Channel 1' },
        { channel: 1, text: 'Channel 2' },
        { channel: 2, text: 'Channel 3' },
        { channel: 3, text: 'Channel 4' },
        { channel: 4, text: 'Channel 5' },
        { channel: 5, text: 'Channel 6' },
        { channel: 6, text: 'Channel 7' },
        { channel: 7, text: 'Channel 8' },
        { channel: 8, text: 'Channel 9' },
        { channel: 9, text: 'Channel 10' },
        { channel: 10, text: 'Channel 11' },
        { channel: 11, text: 'Channel 12' },
        { channel: 12, text: 'Channel 13' },
        { channel: 13, text: 'Channel 14' },
        { channel: 14, text: 'Channel 15' },
        { channel: 15, text: 'Channel 16' }
      ],
      apiFunctionLookup: apiFunctions,
      inputLookup: this.vMixRemote.inputs
    }
  }

  async refresh (): Promise<null> {
    this.inputLookup = await this.vMixRemote.loadInputs()
    return null
  }

  filter (value: unknown, search: string) {
    return (
      value != null &&
      search != null &&
      typeof value === 'string' &&
      value
        .toString()
        .toLocaleUpperCase()
        .indexOf(search.toLocaleUpperCase()) !== -1
    )
  }

  formatApiFunctionParams (trigger: Trigger): string {
    let str = ''

    const params = Trigger.getApiFunctionParams(trigger)
    const input = _.find(this.vMixRemote.inputs, { key: params.Input })

    for (const prop in params) {
      const value = params[prop as keyof ApiFunctionParams]

      if (prop === 'Input' && input) {
        str += `Input: ${input.shortTitle}\n`
      } else if (value !== undefined) {
        str += `${prop}: ${value}\n`
      }
    }

    return str.trim()
  }

  editItem (trigger: Trigger) {
    this.editedIndex = this.vMixRemote.triggers.indexOf(trigger)
    this.editedItem = Object.assign({}, trigger)
    this.editNote = trigger.note
    this.editChannel = trigger.channel
    this.editVelocity = trigger.velocity
    this.dialog = true
  }

  deleteItem (trigger: Trigger) {
    this.editedIndex = this.vMixRemote.triggers.indexOf(trigger)
    this.editedItem = Object.assign({}, trigger)
    this.dialogDelete = true
  }

  async deleteItemConfirm () {
    this.vMixRemote.triggers.splice(this.editedIndex, 1)
    await this.vMixRemote.saveSettings()
    this.closeDelete()
  }

  close () {
    (this.$refs.form as Vue & {
      resetValidation: () => void;
    }).resetValidation()

    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.editNote = ''
      this.editVelocity = ''
      this.editChannel = null

      console.log(this.editedItem)
    })
  }

  closeDelete () {
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.editNote = ''
      this.editVelocity = ''
      this.editChannel = null
    })
  }

  async save () {
    // Fix Vue 2 number binding issue
    // https://github.com/vuejs/vue/issues/7136
    if (this.editVelocity === '') {
      this.editedItem.velocity = null
    } else {
      this.editVelocity = this.editVelocity as number
      this.editedItem.velocity = this.editVelocity as number
    }

    if (this.editChannel === '') {
      this.editChannel = null
      this.editedItem.channel = null
    } else {
      this.editChannel = this.editChannel as number
      this.editedItem.channel = this.editChannel as number
    }

    // Validate
    const valid = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate()

    // Safe to assign note to model now
    this.editedItem.note = +this.editNote

    if (valid) {
      if (this.editedIndex > -1) {
        Object.assign(this.vMixRemote.triggers[this.editedIndex], this.editedItem)
      } else {
        this.vMixRemote.triggers.push(this.editedItem)
      }
      await this.vMixRemote.saveSettings()

      this.close()
    }
  }
}
