/**
 * Supported MIDI events
 */
export enum MidiEvent {
  NoteOn = 1,
  NoteOff
}

/**
 * vMix input
 */
export interface VMixInput {
  key: string;
  index: number;
  shortTitle: string;
}

/**
 * API function parameters
 */
export interface ApiFunctionParams {
  Duration?: number;
  Input?: string;
  SelectedName?: string;
  SelectedIndex?: number;
  Value?: string;
}

/**
 * vMix trigger
 */
export class Trigger implements ApiFunctionParams {
  event: MidiEvent
  note: number
  velocity: number | null
  channel: number | null
  apiFunctionName: string

  Duration?: number;
  Input?: string;
  SelectedName?: string;
  SelectedIndex?: number;
  Value?: string;

  constructor () {
    this.event = MidiEvent.NoteOn
    this.note = -1
    this.velocity = null
    this.channel = null
    this.apiFunctionName = ''
  }

  public static getApiFunctionParams (trigger: Trigger): ApiFunctionParams {
    const params: ApiFunctionParams = {}

    if (trigger.Duration !== undefined) params.Duration = trigger.Duration
    if (trigger.Input !== undefined) params.Input = trigger.Input
    if (trigger.SelectedName !== undefined) params.SelectedName = trigger.SelectedName
    if (trigger.SelectedIndex !== undefined) params.SelectedIndex = trigger.SelectedIndex
    if (trigger.Value !== undefined) params.Value = trigger.Value

    return params
  }
}

/**
 * vMix API function
 */
export interface ApiFunction {
  group: string;
  name: string;
  parameters: ApiFunctionParameter;
}

/**
 * vMix API function parameter
 */
export enum ApiFunctionParameter {
  None = 0,
  Duration = 1 << 0,
  Input = 1 << 1,
  SelectedName = 1 << 2,
  SelectedIndex = 1 << 3,
  Value = 1 << 4
}

const apiFunctions: Array<ApiFunction> = [
  // { group: 'General', name: 'ActivatorRefresh', parameters: ApiFunctionParameter.None },
  // { group: 'General', name: 'CallManagerShowHide', parameters: ApiFunctionParameter.None },
  // { group: 'General', name: 'KeyPress', parameters: ApiFunctionParameter.Value },
  // { group: 'General', name: 'SendKeys', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'Audio', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioAuto', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioAutoOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioAutoOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioBus', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioBusOff', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioBusOn', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioMixerShowHide', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'AudioOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioPluginOff', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioPluginOn', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioPluginOnOff', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'AudioPluginShow', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'BusAAudio', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'BusAAudioOff', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'BusAAudioOn', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'BusAAudioPluginOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusAAudioPluginOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusAAudioPluginOnOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusAAudioPluginShow', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusBAudio', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'BusBAudioOff', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'BusBAudioOn', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'BusBAudioPluginOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusBAudioPluginOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusBAudioPluginOnOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusBAudioPluginShow', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudio', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudioOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudioOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudioPluginOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudioPluginOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudioPluginOnOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXAudioPluginShow', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXSendToMaster', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXSendToMasterOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXSendToMasterOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXSolo', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXSoloOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'BusXSoloOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'MasterAudio', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'MasterAudioOff', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'MasterAudioOn', parameters: ApiFunctionParameter.None },
  // { group: 'Audio', name: 'MasterAudioPluginOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'MasterAudioPluginOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'MasterAudioPluginOnOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'MasterAudioPluginShow', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBalance', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetBusAVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBusBVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBusCVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBusDVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBusEVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBusFVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetBusGVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetGain', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetGainChannel1', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetGainChannel2', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetHeadphonesVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetMasterVolume', parameters: ApiFunctionParameter.Value },
  // { group: 'Audio', name: 'SetVolume', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetVolumeChannel1', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetVolumeChannel2', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetVolumeChannelMixer', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SetVolumeFade', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'Solo', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SoloOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Audio', name: 'SoloOn', parameters: ApiFunctionParameter.Input },
  { group: 'Transition', name: 'CutDirect', parameters: ApiFunctionParameter.Input },
  // { group: 'Transition', name: 'FadeToBlack', parameters: ApiFunctionParameter.None },
  { group: 'Transition', name: 'QuickPlay', parameters: ApiFunctionParameter.Input },
  // { group: 'Transition', name: 'SetFader', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionDuration1', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionDuration2', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionDuration3', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionDuration4', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionEffect1', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionEffect2', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionEffect3', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'SetTransitionEffect4', parameters: ApiFunctionParameter.Value },
  // { group: 'Transition', name: 'Stinger1', parameters: ApiFunctionParameter.Input },
  // { group: 'Transition', name: 'Stinger2', parameters: ApiFunctionParameter.Input },
  // { group: 'Transition', name: 'Transition1', parameters: ApiFunctionParameter.None },
  // { group: 'Transition', name: 'Transition2', parameters: ApiFunctionParameter.None },
  // { group: 'Transition', name: 'Transition3', parameters: ApiFunctionParameter.None },
  // { group: 'Transition', name: 'Transition4', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'Fullscreen', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'FullscreenOff', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'FullscreenOn', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'SetOutput2', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'SetOutput3', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'SetOutput4', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'SetOutputExternal2', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'SetOutputFullscreen', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'SetOutputFullscreen2', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'Snapshot', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'SnapshotInput', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Output', name: 'StartExternal', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StartMultiCorder', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StartRecording', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StartStopExternal', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StartStopMultiCorder', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StartStopRecording', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StartStopStreaming', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'StartStreaming', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'StopExternal', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StopMultiCorder', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StopRecording', parameters: ApiFunctionParameter.None },
  // { group: 'Output', name: 'StopStreaming', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'StreamingSetKey', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'StreamingSetPassword', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'StreamingSetURL', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'StreamingSetUsername', parameters: ApiFunctionParameter.Value },
  // { group: 'Output', name: 'WriteDurationToRecordingLog', parameters: ApiFunctionParameter.Value },
  // { group: 'Title', name: 'AdjustCountdown', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'ChangeCountdown', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'NextTitlePreset', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'PauseCountdown', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'PauseRender', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'PreviousTitlePreset', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'ResumeRender', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SelectTitlePreset', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetCountdown', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetImage', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetImageVisible', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetImageVisibleOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetImageVisibleOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetText', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetTextColour', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetTextVisible', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetTextVisibleOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetTextVisibleOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SetTickerSpeed', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Title', name: 'StartCountdown', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'StopCountdown', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'SuspendCountdown', parameters: ApiFunctionParameter.Input },
  // { group: 'Title', name: 'TitleBeginAnimation', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ActiveInput', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AddInput', parameters: ApiFunctionParameter.Value },
  // { group: 'Input', name: 'AutoPauseOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPauseOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayFirst', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayFirstOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayFirstOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayNext', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayNextOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayNextOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoPlayOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoRestartOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'AutoRestartOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ColourCorrectionAuto', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ColourCorrectionReset', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'CreateVirtualInput', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'DeinterlaceOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'DeinterlaceOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'InputPreviewHide', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'InputPreviewShow', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'InputPreviewShowHide', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListAdd', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListExport', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListPlayOut', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListRemove', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListRemoveAll', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListShowHide', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ListShuffle', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'LivePlayPause', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'LoopOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'LoopOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MarkIn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MarkOut', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MarkReset', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MarkResetIn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MarkResetOut', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MirrorOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MirrorOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'MoveInput', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'NextItem', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'NextPicture', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'Pause', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'Play', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'PlayPause', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'PreviewInput', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'PreviewInputNext', parameters: ApiFunctionParameter.None },
  // { group: 'Input', name: 'PreviewInputPrevious', parameters: ApiFunctionParameter.None },
  // { group: 'Input', name: 'PreviousItem', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'PreviousPicture', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'RemoveInput', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'ResetInput', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'Restart', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SaveVideoDelay', parameters: ApiFunctionParameter.Input | ApiFunctionParameter.Duration },
  // { group: 'Input', name: 'SelectCategory', parameters: ApiFunctionParameter.Value },
  // { group: 'Input', name: 'SelectIndex', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetAlpha', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGainB', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGainG', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGainR', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGainRGB', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGammaB', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGammaG', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGammaR', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCGammaRGB', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCHue', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCLiftB', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCLiftG', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCLiftR', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCLiftRGB', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetCCSaturation', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetInputName', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetPanX', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetPanY', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetPictureEffect', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetPictureEffectDuration', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetPictureTransition', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetPosition', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetRate', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetRateSlowMotion', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SetZoom', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SharpenOff', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'SharpenOn', parameters: ApiFunctionParameter.Input },
  // { group: 'Input', name: 'VideoCallAudioSource', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'VideoCallVideoSource', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Input', name: 'VideoDelayStartRecording', parameters: ApiFunctionParameter.Input | ApiFunctionParameter.Duration },
  // { group: 'Input', name: 'VideoDelayStartStopRecording', parameters: ApiFunctionParameter.Input | ApiFunctionParameter.Duration },
  // { group: 'Input', name: 'VideoDelayStopRecording', parameters: ApiFunctionParameter.Input | ApiFunctionParameter.Duration },
  // { group: 'Input', name: 'WaitForCompletion', parameters: ApiFunctionParameter.Input | ApiFunctionParameter.Duration },
  // { group: 'Overlay', name: 'MoveMultiViewOverlay', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'MultiViewOverlay', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'MultiViewOverlayOff', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'MultiViewOverlayOn', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  { group: 'Overlay', name: 'OverlayInput1', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput1In', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput1Off', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput1Out', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput1Zoom', parameters: ApiFunctionParameter.None },
  { group: 'Overlay', name: 'OverlayInput2', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput2In', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput2Off', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput2Out', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput2Zoom', parameters: ApiFunctionParameter.None },
  { group: 'Overlay', name: 'OverlayInput3', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput3In', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput3Off', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput3Out', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput3Zoom', parameters: ApiFunctionParameter.None },
  { group: 'Overlay', name: 'OverlayInput4', parameters: ApiFunctionParameter.Input }
  // { group: 'Overlay', name: 'OverlayInput4In', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'OverlayInput4Off', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput4Out', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInput4Zoom', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'OverlayInputAllOff', parameters: ApiFunctionParameter.None },
  // { group: 'Overlay', name: 'PreviewOverlayInput1', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'PreviewOverlayInput2', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'PreviewOverlayInput3', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'PreviewOverlayInput4', parameters: ApiFunctionParameter.Input },
  // { group: 'Overlay', name: 'SetMultiViewOverlay', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Playlist', name: 'NextPlayListEntry', parameters: ApiFunctionParameter.None },
  // { group: 'Playlist', name: 'PreviousPlayListEntry', parameters: ApiFunctionParameter.None },
  // { group: 'Playlist', name: 'SelectPlayList', parameters: ApiFunctionParameter.Value },
  // { group: 'Playlist', name: 'StartPlayList', parameters: ApiFunctionParameter.None },
  // { group: 'Playlist', name: 'StopPlayList', parameters: ApiFunctionParameter.None },
  // { group: 'Scripting', name: 'ScriptStart', parameters: ApiFunctionParameter.Value },
  // { group: 'Scripting', name: 'ScriptStartDynamic', parameters: ApiFunctionParameter.Value },
  // { group: 'Scripting', name: 'ScriptStop', parameters: ApiFunctionParameter.Value },
  // { group: 'Scripting', name: 'ScriptStopAll', parameters: ApiFunctionParameter.None },
  // { group: 'Scripting', name: 'ScriptStopDynamic', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayACamera1', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayACamera2', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayACamera3', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayACamera4', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayBCamera1', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayBCamera2', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayBCamera3', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayBCamera4', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayChangeDirection', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayChangeSpeed', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayCopyLastEvent', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayCopySelectedEvent', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayExportLastEvent', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayFastBackward', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayFastForward', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayJumpFrames', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayJumpToNow', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayLastEventCameraOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayLastEventCameraOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayLastEventSingleCameraOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayLive', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayLiveToggle', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMarkCancel', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMarkIn', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMarkInLive', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMarkInOut', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayMarkInOutLive', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayMarkInOutRecorded', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayMarkInRecorded', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMarkInRecordedNow', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMarkOut', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMoveLastEvent', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayMoveSelectedEvent', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayMoveSelectedEventDown', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMoveSelectedEventUp', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayMoveSelectedInPoint', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayMoveSelectedOutPoint', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayPause', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlay', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayAllEvents', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayAllEventsToOutput', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayBackward', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayEvent', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayPlayEventsByID', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayPlayEventsByIDToOutput', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayPlayEventToOutput', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayPlayForward', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayLastEvent', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayLastEventToOutput', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayNext', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayPause', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlayPrevious', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlaySelectedEvent', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayPlaySelectedEventToOutput', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayRecorded', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectedEventCameraOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySelectedEventCameraOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySelectedEventSingleCameraOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySelectEvents1', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents2', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents3', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents4', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents5', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents6', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents7', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectEvents8', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectFirstEvent', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectLastEvent', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectNextEvent', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySelectPreviousEvent', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySetDirectionBackward', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySetDirectionForward', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplaySetLastEventText', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySetLastEventTextCamera', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySetSelectedEventText', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySetSelectedEventTextCamera', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplaySetSpeed', parameters: ApiFunctionParameter.Value },
  // { group: 'Replay', name: 'ReplayShowHide', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayStartRecording', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayStopEvents', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayStopRecording', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleLastEventCamera1', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleLastEventCamera2', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleLastEventCamera3', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleLastEventCamera4', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleSelectedEventCamera1', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleSelectedEventCamera2', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleSelectedEventCamera3', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayToggleSelectedEventCamera4', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayUpdateSelectedInPoint', parameters: ApiFunctionParameter.None },
  // { group: 'Replay', name: 'ReplayUpdateSelectedOutPoint', parameters: ApiFunctionParameter.None },
  // { group: 'NDI', name: 'NDICommand', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'NDI', name: 'NDISelectSourceByIndex', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'NDI', name: 'NDISelectSourceByName', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'NDI', name: 'NDIStartRecording', parameters: ApiFunctionParameter.Input },
  // { group: 'NDI', name: 'NDIStopRecording', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZCreateVirtualInput', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZFocusAuto', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZFocusFar', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZFocusManual', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZFocusNear', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZFocusStop', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZHome', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveDown', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveDownLeft', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveDownRight', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveLeft', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveRight', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveStop', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveToVirtualInputPosition', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveToVirtualInputPositionByIndex', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveUp', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveUpLeft', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZMoveUpRight', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZUpdateVirtualInput', parameters: ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZZoomIn', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZZoomOut', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'PTZ', name: 'PTZZoomStop', parameters: ApiFunctionParameter.Input },
  // { group: 'Preset', name: 'LastPreset', parameters: ApiFunctionParameter.None },
  // { group: 'Preset', name: 'OpenPreset', parameters: ApiFunctionParameter.Value },
  // { group: 'Preset', name: 'SavePreset', parameters: ApiFunctionParameter.Value },
  // { group: 'Data Sources', name: 'DataSourceAutoNextOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Data Sources', name: 'DataSourceAutoNextOn', parameters: ApiFunctionParameter.Value },
  // { group: 'Data Sources', name: 'DataSourceAutoNextOnOff', parameters: ApiFunctionParameter.Value },
  // { group: 'Data Sources', name: 'DataSourceNextRow', parameters: ApiFunctionParameter.Value },
  // { group: 'Data Sources', name: 'DataSourcePreviousRow', parameters: ApiFunctionParameter.Value },
  // { group: 'Data Sources', name: 'DataSourceSelectRow', parameters: ApiFunctionParameter.Value },
  // { group: 'Browser', name: 'BrowserBack', parameters: ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserForward', parameters: ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserKeyboardDisabled', parameters: ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserKeyboardEnabled', parameters: ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserMouseDisabled', parameters: ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserMouseEnabled', parameters: ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserNavigate', parameters: ApiFunctionParameter.Value | ApiFunctionParameter.Input },
  // { group: 'Browser', name: 'BrowserReload', parameters: ApiFunctionParameter.Input }
]

export { apiFunctions }
