import _ from 'lodash'
import xpath from 'xpath'
import { MidiEvent, VMixInput, Trigger, ApiFunctionParams } from './models'
import webmidi, { InputEventNoteon, InputEventNoteoff } from 'webmidi'
import axios, { AxiosRequestConfig } from 'axios'
import xmldom from 'xmldom'

const defaultConfig: AxiosRequestConfig = {
  timeout: 1000
}

/**
 * vMix remote
 */
export class VMixRemote {
  private _isDevelopment: boolean
  private _isOffline: boolean
  private _checkVMixInterval = 0

  isBrowserUnsupported = false

  isVMixConnected = false
  vMixUrl = ''
  inputs: Array<VMixInput> = []
  triggers: Array<Trigger> = []

  isMidiConnected = false

  private _midiInputName = ''

  public get midiInputName (): string {
    return this._midiInputName
  }

  public set midiInputName (name: string) {
    if (this._midiInputName !== name) {
      console.log(`Switching MIDI input from ${this._midiInputName} to ${name}`)

      // Remove listener from old input
      if (webmidi.enabled) {
        const input = webmidi.getInputByName(this._midiInputName)
        if (input) {
          input.removeListener()
        }
      }

      this._midiInputName = name
      this.testMidiConnection()

      // Add listener to new input
      if (webmidi.enabled) {
        const input = webmidi.getInputByName(this._midiInputName)
        if (input) {
          input.addListener('noteon', 'all', (e) => this.midiEventHandler(e))
          input.addListener('noteoff', 'all', (e) => this.midiEventHandler(e))
        }
      }
    }
  }

  midiInputs: Array<string> = []

  /**
   * Creates a new vMix remote object
   * @param isDevelopment development mode flag
   * @param isOffline offline mode flag
   */
  constructor (isDevelopment: boolean, isOffline: boolean) {
    this._isDevelopment = isDevelopment
    this._isOffline = isOffline
  }

  /**
   * Initializes vMix remote
   */
  public async initialize (): Promise<null> {
    try {
      // Initialize webmidi
      await new Promise((resolve, reject) => {
        webmidi.enable((err) => {
          if (err) {
            this.isBrowserUnsupported = true
            reject(err)
          } else {
            resolve(null)
          }
        })
      })
      this.midiInputs = _.map(webmidi.inputs, i => i.name)

      await this.loadSettings()

      // Initialize connection checks
      await this.testVMixConnection()
      this._checkVMixInterval = window.setInterval(async () => await this.testVMixConnection(), 10000)

      // Initialize MIDI input
      this.testMidiConnection()

      if (webmidi.enabled) {
        webmidi.addListener('connected', (e) => {
          console.log('New MIDI device connected', e.port)
          this.midiInputs = _.map(webmidi.inputs, i => i.name)
          this.testMidiConnection()
        })

        webmidi.addListener('disconnected', (e) => {
          console.log('MIDI device disconnected', e.port)
        })
      }
    } catch (err) {
      console.error(err)
    }
    console.log('vMix Remote initialized', this)

    return null
  }

  /**
   * Loads the saved settings
   */
  private async loadSettings (): Promise<null> {
    try {
      console.log('Loading settings...')

      // Get from localStorage
      this.vMixUrl = localStorage.vMixUrl || ''
      this.midiInputName = localStorage.midiInputName || ''
      this.triggers = JSON.parse(localStorage.triggers) || []

      await this.loadInputs()
    } catch (err) {
      console.error(err)

      this.inputs = []
      this.triggers = []
    }

    return null
  }

  /**
   * Saves the settings
   */
  public async saveSettings (): Promise<null> {
    try {
      console.log('Saving settings...', this)

      localStorage.vMixUrl = this.vMixUrl
      localStorage.midiInputName = this.midiInputName
      localStorage.triggers = JSON.stringify(this.triggers)
    } catch (err) {
      console.error(err)
    }

    // Trigger MIDI connection check in case midiInputName got changed
    await this.testMidiConnection()

    return null
  }

  /**
   * Loads vMix inputs
   */
  public async loadInputs (): Promise<Array<VMixInput>> {
    try {
      let url = new URL('/api/', this.vMixUrl).href

      if (this._isDevelopment && this._isOffline) {
        // Get from offline data file
        url = '/data/api.xml'
      }

      const result = (await axios.get(url, defaultConfig)).data
      const doc = new xmldom.DOMParser().parseFromString(result)
      const nodes = xpath.select('/vmix/inputs/input', doc)

      this.inputs = _.map<xpath.SelectedValue, VMixInput>(
        nodes,
        (n: xpath.SelectedValue) => {
          const s = n as Node

          return {
            key: (xpath.select1('./@key', s) as Attr).value,
            index: +(xpath.select1('./@number', s) as Attr).value,
            shortTitle: (xpath.select1('./@shortTitle', s) as Attr).value
          }
        }
      )
    } catch (err) {
      console.error(err)
    }

    return this.inputs
  }

  /**
   * Tests connection to vMix
   */
  public async testConnection (): Promise<boolean> {
    if (this._isDevelopment && this._isOffline) {
      return true
    }

    try {
      const response = await axios.get(this.vMixUrl, defaultConfig)
      return response.status === 200
    } catch (err) {
      console.error(err)
      return false
    }
  }

  /**
   * Tests if configured vMix is available
   */
  private async testVMixConnection (): Promise<boolean> {
    if (this._isDevelopment && this._isOffline) {
      this.isVMixConnected = true
    } else {
      try {
        const response = await axios.get(new URL('/api/', this.vMixUrl).href, defaultConfig)
        this.isVMixConnected = response.status === 200
      } catch (err) {
        console.error(err)
        this.isVMixConnected = false
      }
    }

    return this.isVMixConnected
  }

  /**
   * Tests if configured MIDI input is available
   */
  private testMidiConnection (): boolean {
    this.isMidiConnected = this.midiInputs.indexOf(this.midiInputName) > -1
    return this.isMidiConnected
  }

  /**
   * MIDI event handler
   * @param e The MIDI input event
   */
  private async midiEventHandler (e: InputEventNoteon | InputEventNoteoff) {
    try {
      console.log('Received MIDI note: ', e)

      let promises: Array<Promise<boolean>> = []

      promises = _(this.triggers)
        .filter((t: Trigger) => {
          return (
            ((t.event === MidiEvent.NoteOn && e.type === 'noteon') ||
            (t.event === MidiEvent.NoteOff && e.type === 'noteoff')) &&
            t.note === e.note.number &&
            (t.velocity === null || t.velocity === e.rawVelocity) &&
            (t.channel === null || t.channel === e.channel - 1)
          )
        })
        .map((t: Trigger) => {
          return this.executeFunction(t.apiFunctionName, Trigger.getApiFunctionParams(t))
        })
        .value()

      await Promise.all(promises)
    } catch (err) {
      console.error(err)
    }

    return null
  }

  /**
   * Executes the vMix function
   * @param functionName The function name
   * @param apiParams The function parameters
   */
  public async executeFunction (functionName: string, apiParams: ApiFunctionParams): Promise<boolean> {
    console.log(`Execute function: ${functionName}`, apiParams)

    if (this._isDevelopment && this._isOffline) {
      return true
    }

    const config = Object.assign({ params: Object.assign({ Function: functionName }, apiParams) }, defaultConfig)

    try {
      const response = await axios.get(new URL('/api/', this.vMixUrl).href, config)
      return response.status === 200
    } catch (err) {
      console.error(err)
      return false
    }
  }
}
