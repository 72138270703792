





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { VMixRemote } from '@/app'

@Component
export default class Dashboard extends Vue {
  @Prop() vMixRemote!: VMixRemote
}
