

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import { VMixRemote } from '@/app'

@Component
export default class GeneralSettings extends Vue {
  private loading = false
  private testConnectionResult?: boolean
  private debounceSaveFn: unknown = null

  @Prop() vMixRemote!: VMixRemote

  data () {
    return {
      loading: false,
      testConnectionResult: null,
      debounceSaveFn: null
    }
  }

  async created (): Promise<null> {
    this.debounceSaveFn = _.debounce(this.save, 500)
    return null
  }

  async testConnection (): Promise<null> {
    this.loading = true
    this.testConnectionResult = await this.vMixRemote.testConnection()
    this.loading = false
    return null
  }

  async save (): Promise<null> {
    this.testConnectionResult = undefined
    await this.vMixRemote.saveSettings()
    return null
  }
}
